import React, { useContext } from 'react';
import { StateContext } from '../../utils/context/stateContext';
import './MiniNavigation.scss';

const MiniNavigation = ({ heading, position = 'absolute' }) => {
const { show, toggleState } = useContext(StateContext);

  return (
    <nav className={`mini_navigation ${position}`} style={{'position': position}}>
      <span>{heading}</span>
      <span className="mini_navigation__button" onClick={() => toggleState(!show)}>Close</span>
    </nav>
   );
}

export default MiniNavigation;