import React from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

const Logo = ({ logoText, url }) => {
  return (
    <div className="logo">
      {url
        ? <Link to={url}><h1 className="logo__text">{logoText}</h1></Link>
        : <h1 className="logo__text">{logoText}</h1>
      }
    </div>

   );
}

export default Logo;