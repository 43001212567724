import React, { createContext, useState } from "react";

export const CursorContext = createContext([null, null, null, null]);

export const CursorContextProvider = ({ children }) => {
  const [cursor, setCursor] = useState({ active: true });
  const [direction, setDirection] = useState('down');

  return (
    <CursorContext.Provider value={[cursor, setCursor, direction, setDirection]}>
      {children}
    </CursorContext.Provider>
  );
};
